<template>
  <section>
    <section class="top">
      <div>
        <p class="tt_title">
          TimeTable
        </p>
        <p class="tt_subtitle">
          Creating New Timetable For Class {{ stdClass ? stdClass : '?' }}
        </p>
      </div>
      <div class="tt_class">
        <VForm
          ref="formClass"
          v-model="valid"
          lazy-validation
        >
          <VSelect
            v-model="stdClass"
            :rules="[rules.required]"
            :items="selectClass"
            :hide-details="true"
            placeholder="CLASS"
            class="tt_main_txt"
            flat
            solo
          />
        </VForm>
      </div>
      <div class="top-btn">
        <VBtn
          round
          large
          class="shadow"
          color="white"
          @click="save()"
        >
          Save
        </VBtn>
      </div>
    </section>
    <section class="content">
      <div class="grid">
        <VForm
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <VCard class="shadow tt_card">
            <p class="tt_main_title pl-2">
              Monday
            </p>
            <p class="tt_main_subtitle pl-2">
              Class {{ stdClass ? stdClass : '?' }}
            </p>
            <div>
              <VSelect
                v-model="days.monday.p1"
                :rules="[rules.required]"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-1"
                flat
                solo
              />
              <VSelect
                v-model="days.monday.p2"
                :rules="[rules.required]"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-2"
                flat
                solo
              />
              <VSelect
                v-model="days.monday.p3"
                :rules="[rules.required]"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-3"
                flat
                solo
              />
              <VSelect
                v-model="days.monday.p4"
                :rules="[rules.required]"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-4"
                flat
                solo
              />
              <VSelect
                v-model="days.monday.p5"
                :rules="[rules.required]"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-5"
                flat
                solo
              />
              <VSelect
                v-model="days.monday.p6"
                :rules="[rules.required]"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-6"
                flat
                solo
              />
              <VSelect
                v-model="days.monday.p7"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-7"
                flat
                solo
              />
            </div>
          </VCard>
        </VForm>
        <VForm
          ref="form1"
          v-model="valid"
          lazy-validation
        >
          <VCard class="shadow tt_card">
            <p class="tt_main_title pl-2">
              Tuesday
            </p>
            <p class="tt_main_subtitle pl-2">
              Class {{ stdClass ? stdClass : '?' }}
            </p>
            <div>
              <VSelect
                v-model="days.tuesday.p1"
                :rules="[rules.required]"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-1"
                flat
                solo
              />
              <VSelect
                v-model="days.tuesday.p2"
                :rules="[rules.required]"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-2"
                flat
                solo
              />
              <VSelect
                v-model="days.tuesday.p3"
                :rules="[rules.required]"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-3"
                flat
                solo
              />
              <VSelect
                v-model="days.tuesday.p4"
                :rules="[rules.required]"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-4"
                flat
                solo
              />
              <VSelect
                v-model="days.tuesday.p5"
                :rules="[rules.required]"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-5"
                flat
                solo
              />
              <VSelect
                v-model="days.tuesday.p6"
                :rules="[rules.required]"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-6"
                flat
                solo
              />
              <VSelect
                v-model="days.tuesday.p7"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-7"
                flat
                solo
              />
            </div>
          </VCard>
        </VForm>
        <VForm
          ref="form2"
          v-model="valid"
          lazy-validation
        >
          <VCard class="shadow tt_card">
            <p class="tt_main_title pl-2">
              Wednesday
            </p>
            <p class="tt_main_subtitle pl-2">
              Class {{ stdClass ? stdClass : '?' }}
            </p>
            <div>
              <VSelect
                v-model="days.wednesday.p1"
                :rules="[rules.required]"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-1"
                flat
                solo
              />
              <VSelect
                v-model="days.wednesday.p2"
                :rules="[rules.required]"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-2"
                flat
                solo
              />
              <VSelect
                v-model="days.wednesday.p3"
                :rules="[rules.required]"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-3"
                flat
                solo
              />
              <VSelect
                v-model="days.wednesday.p4"
                :rules="[rules.required]"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-4"
                flat
                solo
              />
              <VSelect
                v-model="days.wednesday.p5"
                :rules="[rules.required]"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-5"
                flat
                solo
              />
              <VSelect
                v-model="days.wednesday.p6"
                :rules="[rules.required]"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-6"
                flat
                solo
              />
              <VSelect
                v-model="days.wednesday.p7"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-7"
                flat
                solo
              />
            </div>
          </VCard>
        </VForm>
        <VForm
          ref="form3"
          v-model="valid"
          lazy-validation
        >
          <VCard class="shadow tt_card">
            <p class="tt_main_title pl-2">
              Thursday
            </p>
            <p class="tt_main_subtitle pl-2">
              Class {{ stdClass ? stdClass : '?' }}
            </p>
            <div>
              <VSelect
                v-model="days.thursday.p1"
                :rules="[rules.required]"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-1"
                flat
                solo
              />
              <VSelect
                v-model="days.thursday.p2"
                :rules="[rules.required]"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-2"
                flat
                solo
              />
              <VSelect
                v-model="days.thursday.p3"
                :rules="[rules.required]"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-3"
                flat
                solo
              />
              <VSelect
                v-model="days.thursday.p4"
                :rules="[rules.required]"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-4"
                flat
                solo
              />
              <VSelect
                v-model="days.thursday.p5"
                :rules="[rules.required]"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-5"
                flat
                solo
              />
              <VSelect
                v-model="days.thursday.p6"
                :rules="[rules.required]"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-6"
                flat
                solo
              />
              <VSelect
                v-model="days.thursday.p7"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-7"
                flat
                solo
              />
            </div>
          </VCard>
        </VForm>
        <VForm
          ref="form4"
          v-model="valid"
          lazy-validation
        >
          <VCard class="shadow tt_card">
            <p class="tt_main_title pl-2">
              Friday
            </p>
            <p class="tt_main_subtitle pl-2">
              Class {{ stdClass ? stdClass : '?' }}
            </p>
            <div>
              <VSelect
                v-model="days.friday.p1"
                :rules="[rules.required]"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-1"
                flat
                solo
              />
              <VSelect
                v-model="days.friday.p2"
                :rules="[rules.required]"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-2"
                flat
                solo
              />
              <VSelect
                v-model="days.friday.p3"
                :rules="[rules.required]"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-3"
                flat
                solo
              />
              <VSelect
                v-model="days.friday.p4"
                :rules="[rules.required]"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-4"
                flat
                solo
              />
              <VSelect
                v-model="days.friday.p5"
                :rules="[rules.required]"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-5"
                flat
                solo
              />
              <VSelect
                v-model="days.friday.p6"
                :rules="[rules.required]"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-6"
                flat
                solo
              />
              <VSelect
                v-model="days.friday.p7"
                :items="selectSubject"
                :hide-details="true"
                placeholder="Select Subject"
                class="tt_main_txt"
                prepend-icon="mdi-numeric-7"
                flat
                solo
              />
            </div>
          </VCard>
        </VForm>
      </div>
    </section>
    <VDialog
      v-model="isLoading"
      width="300"
      persistent
    >
      <VCard
        v-if="loadStatus === 'processing'"
        class="notification_card elevation-20"
      >
        <p>Processing</p>
        <span>Saving Data, Please Wait...</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="primary"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'success'"
        class="notification_card elevation-20"
      >
        <p>Saved</p>
        <span>TimeTable Created For Class {{ stdClass }}</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="primary"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'exists'"
        class="notification_card elevation-20"
      >
        <p>Warning</p>
        <span>TimeTable Exists For Class {{ stdClass }}</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="orange"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'netfail'"
        class="notification_card elevation-20"
      >
        <p>Internet Issue</p>
        <span>Please Try Again Later</span>
        <div class="notification_ico">
          <VProgressCircular
            indeterminate
            color="red"
            :size="30"
            :width="3"
          />
        </div>
      </VCard>
      <VCard
        v-if="loadStatus === 'fail'"
        class="notification_card elevation-20"
      >
        <p>Error</p>
        <span>An Error Occured</span>
        <div class="notification_ico">
          <VBtn
            depressed
            round
            color="primary"
            @click="reloadPage()"
          >
            Reload
          </VBtn>
        </div>
      </VCard>
    </VDialog>
    <VSnackbar
      v-model="snackbar"
      color="secondary"
      :timeout="2500"
      :multi-line="true"
    >
      {{ snackbarTxt }}
      <VBtn
        color="primary"
        depressed
        style="width:100px;"
        round
        @click="snackbar = false"
      >
        Close
      </VBtn>
    </VSnackbar>
  </section>
</template>

<script>
import axios from 'axios';
import api from '../../conf/api';

export default {
  data: () => ({
    isLoading: false,
    loadStatus: '',
    retry: 0,
    snackbar: false,
    snackbarTxt: '',
    valid: true,
    stdClass: '',
    selectClass: [],
    selectSubject: [],
    days: {
      monday: {
        p1: '',
        p2: '',
        p3: '',
        p4: '',
        p5: '',
        p6: '',
        p7: null,
      },
      tuesday: {
        p1: '',
        p2: '',
        p3: '',
        p4: '',
        p5: '',
        p6: '',
        p7: null,
      },
      wednesday: {
        p1: '',
        p2: '',
        p3: '',
        p4: '',
        p5: '',
        p6: '',
        p7: null,
      },
      thursday: {
        p1: '',
        p2: '',
        p3: '',
        p4: '',
        p5: '',
        p6: '',
        p7: null,
      },
      friday: {
        p1: '',
        p2: '',
        p3: '',
        p4: '',
        p5: '',
        p6: '',
        p7: null,
      },
    },
    rules: {
      required: (val) => {
        if (val == null) {
          return 'Required';
        }
        if (val === '') {
          return 'Required';
        }
        return true;
      },
    },
  }),
  async beforeMount() {
    axios.get(`${api}/settings/classes`).then((res) => {
      this.selectClass = res.data.data;
    });
    axios.get(`${api}/settings/subjects`).then((res) => {
      this.selectSubject = res.data.data;
    });
  },
  methods: {
    reloadPage() {
      setTimeout(() => {
        window.location.reload();
      }, 400);
    },
    save() {
      if (
        this.$refs.formClass.validate()
        && this.$refs.form.validate()
        && this.$refs.form1.validate()
        && this.$refs.form2.validate()
        && this.$refs.form3.validate()
        && this.$refs.form4.validate()
      ) {
        const ttSchema = {
          class: this.stdClass,
          day: this.days,
        };
        this.isLoading = true;
        this.loadStatus = 'processing';
        axios.post(`${api}/timetable/add`, ttSchema).then((res) => {
          if (res.data.status === 'success') {
            this.loadStatus = 'success';
            setTimeout(() => {
              this.isLoading = false;
              this.$router.push('/timetable');
            }, 2500);
          } else {
            this.loadStatus = 'exists';
            setTimeout(() => {
              this.isLoading = false;
            }, 2500);
          }
        }).catch((e) => {
          if (e.code === 'auth/network-request-failed') {
            this.loadStatus = 'netfail';
            setTimeout(() => {
              this.isLoading = false;
            }, 5000);
          } else {
            this.loadStatus = 'fail';
          }
        });
      } else {
        this.snackbar = true;
        this.snackbarTxt = 'Please Fill In All The Fields';
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '../../assets/stylus/TimeTable/new';
</style>
